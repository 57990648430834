<template lang="pug">
  v-card
    .right-panel-layout.fill-layout-height
      .right-panel-layout__content
        slot
      .right-panel-layout__panel
        slot(name="panel")
</template>

<script>
export default {
  name: 'RightPanelLayout'
}
</script>

<style lang="scss" scoped>
  $right-panel-width: 380px;

  .right-panel-layout {
    display: flex;
    width: 100%;

    &__content {
      position: relative;
      width: calc(100% - #{$right-panel-width});
    }

    &__panel {
      width: $right-panel-width;
      border-left: 1px solid $light-blue;
    }
  }
</style>
